import ModelContainer from '@core/scripts/components/model-container';

function load() {
    const navigationModel = ModelContainer.get('navigation');
    const userModel = ModelContainer.get('headerUserModel');
    const wishlistModel = ModelContainer.get('wishlistGlobalModel');

    return {
        navigationModel,
        userModel,
        wishlistModel,
    };
}

export default {
    load,
};
